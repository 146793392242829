dmx.Component('video', {

  constructor: function(node, parent) {
    this.inView = this.inView.bind(this);
    dmx.BaseComponent.call(this, node, parent);
  },

  initialData: {
      position: 0,
      duration: 0,
      ended: false,
      muted: false,
      paused: false,
      playbackRate: 1,
      volume: 1,
      inView: false
  },

  tag: 'video',

  attributes: {
    /*
    autoplay: {
      type: Boolean,
      default: false
    },

    controls: {
      type: Boolean,
      default: false
    },

    loop: {
      type: Boolean,
      default: false
    },

    muted: {
      type: Boolean,
      default: false
    },

    preload: {
      type: String,
      default: 'metadata' // none, metadata, auto
    },

    poster: {
      type: String,
      default: null
    },
    */

    src: {
      type: String,
      default: null
    },

    autopause: {
      type: Boolean,
      default: false
    },

    playinview: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    position: function(pos) {
      this.$node.currentTime = pos;
    },

    playbackRate: function(rate) {
      this.$node.playbackRate = rate;
    },

    volume: function(volume) {
      this.$node.volume = volume;
    },

    load: function(src) {
      this.$node.src = src;
      this.$node.load();
    },

    play: function() {
      this.$node.play();
    },

    pause: function() {
      this.$node.pause();
    }
  },

  render: function(node) {
    dmx.BaseComponent.prototype.render.call(this, node);

    this.$node.ondurationchange = dmx.requestUpdate;
    this.$node.onended = dmx.requestUpdate;
    this.$node.onpause = dmx.requestUpdate;
    this.$node.onplay = dmx.requestUpdate;
    this.$node.onplaying = dmx.requestUpdate;
    this.$node.onratechange = dmx.requestUpdate;
    this.$node.onseeked = dmx.requestUpdate;
    this.$node.ontimeupdate = dmx.requestUpdate;
    this.$node.onvolumechange = dmx.requestUpdate;

    window.addEventListener('scroll', this.inView);
    window.addEventListener('resize', this.inView);

    this.update({});
    this.inView();
  },

  inView: function() {
    var rect = this.$node.getBoundingClientRect();
    var inView = (
      rect.bottom > 0 &&
      rect.right > 0 &&
      rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth)
    );

    if (!inView && this.data.inView && this.props.autopause) {
      this.$node.pause();
    }

    if (inView && !this.data.inView && this.props.playinview) {
      this.$node.play();
    }

    this.set('inView', inView);
  },

  update: function(props) {
    /*
    this.$node.autoplay = this.props.autoplay;
    this.$node.controls = this.props.controls;
    this.$node.loop = this.props.loop;
    this.$node.muted = this.props.muted;

    if (props.preload != this.props.preload) {
      this.$node.preload = this.props.preload;
    }

    if (props.poster != this.props.poster) {
      this.$node.poster = this.props.poster;
    }
    */

    if (props.src != this.props.src) {
      this.$node.src = this.props.src;
      this.$node.load();
    }

    this.set('position', this.$node.currentTime);
    this.set('duration', this.$node.duration == NaN ? 0 : this.$node.duration);
    this.set('ended', this.$node.ended);
    this.set('muted', this.$node.muted);
    this.set('paused', this.$node.paused);
    this.set('playbackRate', this.$node.playbackRate);
    this.set('volume', this.$node.volume);
  },

  destroy: function() {
    window.removeEventListener('scroll', this.inView);
    window.removeEventListener('resize', this.inView);
  }

});
